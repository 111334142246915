/*
 Theme Name: Common default css - Virtual Office
 Version: 1
 Author: Wade Berlin
 Last edited: Jaspreet Kaur - August 19, 2020
 */

.form_container li {
	list-style: none;
	width: 99%;
}
.form_container li b {
	display: inline-block;
	width: 120px;
	text-align: right;
}
.customer_search_form {
	position: relative;
	display: block;
	padding: 0 0 10px;
}
.search_property.listing_age {
	margin-bottom: 10px;
}
.customer_search_form li b {
	width: 100px !important;
}
.customer_search_form label {
	vertical-align: top;
	width: 150px;
	display: inline-block;
}
.search_title {
	width: 350px;
}
.customer_search_form div.searchprice {
	display: inline-block;
	width: auto;
}
.customer_search_form .submitbtn {
	position: absolute;
	display: block;
	width: 150px;
	bottom: 30px;
	right: 5px;
}
div.search_links {
	position: absolute;
	display: block;
	width: 132px;
	top: 5px;
	right: 5px;
	text-align: right;
}
div.search_id {
	position: absolute;
	display: block;
	width: auto;
	top: 5px;
	left: 5px;
	text-align: center;
	font-size: 2.6rem;
	font-weight: 900;
}
.account_settings.saved_search .search_links {
	top: 35px;
}
.account_settings.saved_search .view-search-link {
	display: inline-block;
}

/**
 *     Virtual Office Like Function Styles.
 */

 .like_off,
 .like_on:hover,
 .a.menu_log.like_nli,.like_nli, 
 .like_login {
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACcElEQVR4AdVXA6xlMRC9axvRj7O2zWBt27Zt27Zt28Ha3uDbfn7NbGfRdO6z3zvJ+Zh2Oud2plKCEjBRacq5ivMJZ9S/32c4J3OG2fEL4xzPeeqfTxJnCucNzrnY7ihwY84XnBkOiINXl/wqcz5wwg8531bwro6dCdM423L2ddEPeUIdvDrpMKdYBtxeaoDI1wx0aYC/4csNExztp4NpeWwPPCVnBvaBT1dN6ANmAwAzA/x6zNCu6j9eCCDTt7qKBtJjwSZiPjJYVlpjEXxDXQ3EfWFgD3dXGFR+ef/kj3xB4i8GjqBJBtjZUif8tjXXgFELTmF3G3kmRqKAucJwfoIBnAWmBmdiVuEMSI0Cp/H+vEkScAQF3BAGzJUrwFw/3mIAF4BiJQHfUECUMOBXuQpDJrgKuQ4U8c/kbBngD5gNsoAkBX8IAzb6GikRTBLwBQW8EwZcAT4G7ieSgDMoYIcwvDhsAl/j3Fh5GU5GAf2FYW97HfgSuFfMLSlvROVRQEFhQEa9810anu2Qd8JX8la8TjSsra7xSTHGf2cwo4D89Z1lAWU5U0QjHhzMDF5DZgLAinLy+fHM2nHcnaTi5BDv1ANubjir9BCqaetOMJ90xIr1tOg2NVAHb+/oVrSMOFycbHB7xxMnpmB3xQnQokRememaCKyfQz3UwQe6ejHdQga4tdh5EVg/NPhod2/Hu8hAeE1zBEwZDT7T0yv6ITLgo422ReAs0eDLvPVOOEYGtnYJQRsNvsXbj5VLNkXgIUaDH/LVi+mKuibwjoeXGcl+hXr5WgTlI8XHoDVB+Yqzor8fsLM5j3CO5MyphCJ+A+1zH/yUV6fCAAAAAElFTkSuQmCC') no-repeat;
 }
 .like_on,
 .like_off:hover,
 .like_nli:hover{
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAE5klEQVR4AcWRA5AkSRhGv8yq7tKgZznG2bZt22boFDgbgbMRvA2cbdu2NfY0q7oqK/P+zlnv9m7PrF7EN+jumfcqE6sahoX4+ezaPQDsS9tk9gZofbRXaQ/TerBkptH2oh1Kq6etQaui/UJ7j/bQ7J81jIQooRR2AnCm17bxEW7TejxZO8O0aqbzuFiQcRig0P+HyPz6QShyI/cCuEWHLYhLu8Rwas73Wjc2vZYNTCNp8YRXx7mRQJQflf5wlxj9/nUhskO3ArhCB/x0Vm1JvhkzzHfrdzzJdrwaU6X7oPIjUMU8wDiYXQVWPQMs1YB050/hyDcvhSoWRwN4CePsB8Yfrdtoz2SqY/MkcoNQuSEoPwOEPgAFJF3w2nqgrgV9nz1V8Ht/uwnA9ezHM3XAfTO3PeoMlysz7v+V5KNA5EOJCGAMzEwClgdOEXzmWijypOh9b1agRLgjAE7vv9uw00m2jYj+/g+o7ADF56CiIiBjQEnASIDZ1eBTWsCbNsR/787KxUGuwzh3U3sze3r7HXUzmq343y8gR3v0k6s4Gv9DGYNE+jVJYaWTSThVPNmwLs91/Qxii5lbH7qVHeVM8e9XiIf/gfLTJA8AKQAlaUr/rEoPVUiDgahrhj/wT8ZUEqdXN66dlL2/IE4PATJCWWSIeKyfggSc9i1Mt3Hd00r3azNlir+/ocBhLSqPop4C2EgnnPr1S+69TaWwg2VyHmcHyR1CQWGJxCGFDkJ2fo+6+tWTAEPY+QNkZlDLK4EVMjCEz8m9jikl6lmQ45KOOI4lKiKOoOgkWBRxECo3DBVFqBQZFcGLOZC7ylQSQOjTixGUlKgUFRSAsAiNvmuFipECohiQD0MmOfuiKJzGoHjFJ6CRNIGJwkAmcMhYSTk74IsgCNdzmMGVVFAKKxTOATADvh+UAj4wlcS7hXzhONuwTCCPWEisSAzLABIOcumMIPeLXCo8NtbXLWTChZngoKwVNqUUDNNAaNgyO9QvyP0WBxCIKL5hKBMGzLRgGByxUCtkiQQHEhaGRwthHKuLSl3sw8NrQVAhu6+hcdoZnhgzC1kfIpJYnli2CcuzkeWpsL938F4AF4DgJEZpKsZDvd0jgc+rhFNlgRtAHMvlMjPBkHQSyMMT/d1DIbnuoaE09t5BKcyGQrAH4/zppvpq25EZM5/2EYYSy4LtGHCqbOR5jejtzQRKqT0BfILZsHcOSGF+pBqPaK6vsp04Y+YmH6HlbvW4vKc3u4hcB7y1fwoLo+TsiEZvPGLMR7E4sQinJK8hOastJ9ewN/ddJGCB62gpRci0mR0NUAziyuSuAa+W5KgV3b25cnINe31vHbCUCNd2FUWMLD3C8cblOaREd88S5Rr26p5lAxa4jtYmR0dkKCIIJBaH6xqoSlla3tWdX5pcw17ZfZGAsifR1lyKGBuP8OWCcm+2XKVEZ3ehErmGvbRrCpWg1LwIjyLSFOHPjvBIXk3y7MTkGvbiLmUDylwHe7q9ZU5EEYwDNSTPoE50dk5IrmEv7JTCRFAKe1LEU+0tto4AMFvuT1SuYc/vqAMmGrErRTzX0ebasWL479/8ZOQa9twOOmAyEVswzh+D0hwO4BtMAvbs9im4Vz++OoAMbRCVYyEWbeA8AcZHAKRpRZos56JZszeFNqr3+uuvr9Kt8oD/AXD2F7HA5zdfAAAAAElFTkSuQmCC') no-repeat;
 }


.like_off {
	display: inline-block;
	margin-left: 10px;
	width: 22px;
	height: 22px;
	background-size: contain;
	-webkit-transition: all 1s linear;
	-moz-transition: all 1s linear;
	-o-transition: all 1 linear;
	transition: all 1s linear;
}
.like_on {
	display: inline-block;
	margin-left: 10px;
	width: 22px;
	height: 22px;
	background-size: contain;
	-webkit-transition: all 1s linear;
	-moz-transition: all 1s linear;
	-o-transition: all 1 linear;
	transition: all 1s linear;
}
.like_off:hover {
	background-size: contain;
}
.like_on:hover {
	background-size: contain;
}
span.like_text {
	visibility: hidden;
	text-indent: -9999px;
}
.a.menu_log.like_nli,
.like_nli {
	display: inline-block;
	margin-left: 10px;
	width: 22px;
	height: 22px;
	overflow: hidden;
	padding: 0px !important;
	background-size: contain;
	-webkit-transition: all 1s linear;
	-moz-transition: all 1s linear;
	-o-transition: all 1 linear;
	transition: all 1s linear;
}
.like_nli:hover {
	background-size: contain;
}
a.menu_log.menu_email_capture {
	position: relative !important;
	display: inline;
	float: none;
}

/**
 *     Default Support for the like widget
 * @note the widget inherits a lot of style from the featured properties css
 */
.customer_likes {
	text-align: center;
	border: 1px solid #030303;
}
.customer_prop {
	text-align: center;
	font-weight: bold;
	color: #030303;
	margin-bottom: 10px;
	border: 0px;
}
.customer_prop img {
	width: 190px;
	border: 0px;
	margin: 2px;
}

/**
 *     Testimonial additions
 */
.customer_testimonial li b {
	vertical-align: top;
}
input.testimonial_title {
	width: 75%;
}
textarea.testimonial_message {
	width: 75%;
	height: 80px;
}

/* like icon on login to like link */
.like_login {
	text-indent: -999999px;
	background-size: contain;
	width: 22px;
	height: 22px;
	display: inline-block;
	cursor: pointer;
}

/* Wade Edits*/
.account-dashboard {
	overflow: visible !important;
	float: none;
	position: relative;
}
.widget {
	position: relative;
	overflow: hidden;
	width: 49%;
	float: left;
	box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.5);
	color: #000;
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 5px;
	color: #000;
}
.widget:first-child {
	margin-left: 0px;
}
.widget:last-child {
	margin-right: 0px;
}
.widget .widget-title {
	font-weight: bold;
	padding: 10px;
	background-color: #eee;
	box-sizing: border-box;
}
.widget > .widget-inner {
	height: 300px;
	overflow: scroll;
}
.widget > .widget-inner .widget-subtitle {
	font-weight: bold;
	font-size: 1.4rem;
}
.widget > .widget-inner > div {
	overflow: hidden;
	padding: 5px 10px;
	border-bottom: 1px solid #eee;
	box-sizing: border-box;
}
.widget > .widget-inner > div li {
	padding: 5px 0;
}
.widget_listings > .widget-inner > div li,
.widget_create_poll > .widget-inner > div li,
.widget_create_testimonial > .widget-inner > div li,
.widget_fsbo_pocket_listings > .widget-inner > div li {
	border-bottom: 1px solid #eee;
	padding: 5px 10px;
	margin: 0px -10px;
}
.widget_listings > .widget-inner > div li:last-child,
.widget_create_poll > .widget-inner > div li:last-child,
.widget_create_testimonial > .widget-inner > div li:last-child,
.widget_fsbo_pocket_listings > .widget-inner > div li:last-child {
	border-bottom: none;
}
.widget > .widget-inner > div.agentpage li b,
.widget > .widget-inner > div.agentpage li {
	margin-right: 0;
	margin-bottom: 0;
}
.widget_id_1 {
	margin: 0px 1% 10px 0px;
}
.widget_id_2 {
	margin: 0px 0px 10px 0px;
}
.widget .agentpage img {
	max-width: 100px;
	height: auto;
	position: static;
	margin-right: 10px;
}
.widget-footer {
	padding: 15px 10px;
	background-color: #eee;
	min-height: 43px;
	box-sizing: border-box;
}
.widget-add {
	position: absolute;
	right: 5px;
	bottom: 15px;
}
.widget-add a,
.widget-footer a {
	border-radius: 5px;
	padding: 4px 10px;
	font-size: 1.3rem;
	margin: 0 !important;
}
.widget-footer a {
	padding: 4px 0;
}
.widget-add a:hover,
.widget-add a:active,
.widget-footer a:hover,
.widget-footer a:active {
	position: relative;
	top: 2px;
	box-shadow: none;
	cursor: pointer;
}
.widget_id_3 {
	margin: 0px 0 20px 0px;
}
.theCustomer {
	display: block !important;
	width: 100%;
}

/* view profile widgets */
.profile-widget-body .customer-address,
.profile-widget-body .customer-zipcode {
	display: block;
}

/* two column notice widget */
.widget-notice-container {
	width: 66.1%;
}
.widget .widget-title {
	color: #000000;
}

/* removes disabled x scroll bars (visible on pretty much everything except an OSX system */
.widget > .widget-inner {
	overflow-x: hidden;
}
.account-dashboard-footer:after {
	content: "";
	display: block;
	clear: both;
}
div.clickable-widget {
	position: relative;
}
div.clickable-widget a {
	width: 100%;
	height: 100%;
	line-height: 20px;
	text-decoration: none; /* No underlines on the link */
	z-index: 10; /* Places the link above everything else in the div */
	background-color: #fff; /* Fix to make div clickable in IE */
}
