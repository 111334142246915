/*
Theme Name: Common default css - Login/Register PopUp
Version: 1
Author: Jaspreet Kaur
Last edited: Jaspreet Kaur - August 19, 2020
*/

@import url(font-awesome.min.css);

@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.3.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'),
       url('../fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'),
       url('../fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'),
       url('../fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'),
       url('../fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'open_sanssemibold';
    src: url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.popup-overlay {
  display: none;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.9);
  z-index: 999;
}

.popupform {
	background: #0d0e12;
	box-shadow: 2.5px 4.33px 9px 0px rgba(0, 0, 1, 0.45);
	position: fixed;
	color:#fff;
	font-family: 'open_sanssemibold';
	font-size: 1.8rem;
  	text-align: left;
	padding:20px 30px;
	box-sizing:border-box;
	margin:0 auto;
    top: 20px;
}

#login-popup {
	width:410px;
	left: 50%;
	margin-left: -205px;
}

#register-popup {
	width:850px;
	left: 50%;
	margin-left: -425px;
}

#recover-popup {
  width:410px;
  left: 50%;
  margin-left: -205px;
}

.popHead {
	color: #fff;
	display:block;
	font-size: 2.8rem;
	line-height:30px;
	font-weight:400;
	border-bottom:1px solid #000;
	padding-bottom:15px;
}

.closePopup {
	position: absolute;
	right: 20px;
	top:10px;
	padding: 10px;
	font-size: 2.0rem;
	font-weight:800;
	color: #979696;
	cursor:pointer;
}

.social_links {
	overflow:hidden;
	margin-top:10px;
}

.popupform h1.fb_login, .popupform a.fb_login, .popup_left h1 {
	padding: 22px 10px 0px;
	font-size: 1.8rem;
	text-transform:none;
	color:#fff;
	font-weight:600;
	display:block;
	margin:0px;
    text-align:left;
    background:none;
	float:left;
    font-variant: normal;
}

.social_link {
	padding:10px 0;
	float:left;
}
.social_link .fbLogin, .popupform #register-popup .fbLogin:hover {
	background: url("../images/facebook_button.png") no-repeat;
	width:282px;
	height:40px;
	display:block;
	cursor:pointer;
}

.popupform h2 {
	text-transform:uppercase;
	text-align:center;
}

.popup_left {
	border-top:1px solid #2f2f2f;
}

.login_seperator {
	text-align:center;
	display:block;
	position:relative;
	text-transform:lowercase;
	font-size: 2.8rem;
	line-height:28px;
	margin:5px 0px 15px;
}
.login_seperator:before, .login_seperator:after {
	border-top:1px solid #2f2f2f;
	position:absolute;
	top:17px;
	content:"";
	width:42%;
	height:1px;
}
.login_seperator:before {
	left:0px;
}
.login_seperator:after {
	right:0px;
}

.register_fields {
	float:left;
	width: 350px;
}

.popupform ul {
	padding:0px;
}
.popupform li {
	margin-bottom:18px;
	list-style:none;
	position:relative;
}
.popupform li div {
	position:relative;
}
.popupform input[type="text"], .popupform input[type="password"], 
.popupform input[type="email"], .popupform input[type="tel"] {
  padding: 0px 40px 0px 15px;
  margin-top: 5px;
  width: 350px;
  border: 1px solid #494848;
  background:#0d0e12;
  height:50px;
  line-height:50px;
  box-sizing:border-box;
  font-weight:400;
  font-size: 1.7rem;
  color:#979696;
  transition: box-shadow 0.5s ease;
}
.popupform input[type="text"]:focus,
.popupform input[type="password"]:focus,
.popupform input[type="email"]:focus,
.popupform input[type="tel"]:focus {
  -webkit-box-shadow: 0px 0px 4.5px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0px 0px 4.5px 1px rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4.5px 1px rgba(255, 255, 255, 1);
}
.popupform input[type="submit"] {
  display: block;
  font-size: 1.5rem;
  height:50px;
  line-height:50px;
  box-sizing:border-box;
  padding: 0px 15px;
  margin-top: 5px;
  margin-bottom:10px;
  width: 350px;
  text-transform: uppercase;
  cursor: pointer;
  border:none;
  background:#939395;
  -webkit-box-shadow: 0.5px 0.866px 0px 0px rgba(0, 0, 0, 0.2), inset 0.5px 0.866px 0px 0px rgba(255, 255, 255, 0.38);
  -moz-box-shadow: 0.5px 0.866px 0px 0px rgba(0, 0, 0, 0.2), inset 0.5px 0.866px 0px 0px rgba(255, 255, 255, 0.38);
  box-shadow: 0.5px 0.866px 0px 0px rgba(0, 0, 0, 0.2), inset 0.5px 0.866px 0px 0px rgba(255, 255, 255, 0.38);
  color: #121317;
  transition: background 0.5s ease;
  font-family: 'open_sanssemibold';
}
.popupform input[type="submit"]:hover {
	background:#fff;
}
.popupform .field_msg {
	display:block;
	margin-top:5px;
	font-size: 1.4rem;
	font-weight:400;
	color:#979696;
}

.required {
	color:#F00;
	padding:5px;
	font-size: 1.4rem;
	display:none;
}

.register_help {
	float:right;
	width:50%;
	font-size: 1.4rem;
}
.register_help h2, .registration_title {
	text-align:left;
	font-weight:600;
	font-size: 2.4rem;
  	color: #fff;
 	margin-bottom: 10px;
  	background: none;
}
.register_help ul {
	margin-top: 20px;
}

.login_link {
	overflow:hidden;
	width:100%;
	line-height: 30px;
}

.popupform a, #register-popup a, #recover-popup a {
	color:#979696 !important;
	font-size: 1.8rem;
	font-weight:600;
	text-decoration:none;
	float: none;
}
#register-popup .social_links a.fb_login:hover {
  text-decoration: none;
}

.popupform a:hover, #register-popup a:hover, #recover-popup a:hover {
	color:#979696;
	text-decoration:underline;
}
.popupform .form_container {
  margin: 20px 0px 0px;
}
.popupform a.menu_registration {
	float:right;
	background:#15161a;
	border:1px solid #676767;
	padding:0px 15px;
	height:39px;
	line-height:39px;
	position:relative;
	transition: all 0.5s ease;
	font-weight: 600;
	-webkit-box-shadow: 0px 3px 0px #676767;
	-moz-box-shadow: 0px 3px 0px #676767;
	box-shadow: 0px 3px 0px #676767;
}
.popupform a.menu_registration:hover {
	text-decoration:none;
	color:#15161a !important;
	background:#676767;
	font-weight: 600;
	border-top:1px solid #fff;
}
.popupform a.forgot_password:hover, #register-popup .login_link a:hover, #recover-popup a:hover {
  background: none;
  color: #979696;
  font-weight: 600;
}

.fa-user, .fa-lock, .fa-envelope, .fa-phone, .fa-calendar {
	position:absolute;
	top:20px;
	right:20px;
	color:#979696;
}

.login_form {
	padding:0px;
}

#reset_acc {
    margin-top: 8px;
    display: inline-block;
}

.error {
	color: #f00!important;
	margin-top: 5px;
	font-weight:400;
	font-size: 1.4rem;
}

.popupform input[type="text"].error:focus,
.popupform input[type="password"].error:focus,
.popupform input[type="email"].error:focus,
.popupform input[type="tel"].error:focus {
	-webkit-box-shadow: 0px 0px 5px 1px rgba(255, 0, 0, 1);
  	-moz-box-shadow: 0px 0px 5px 1px rgba(255, 0, 0, 1);
  	box-shadow: 0px 0px 5px 1px rgba(255, 0, 0, 1);
	border: 1px solid rgba(255, 0, 0, 1);
}
.popupform input.error {
	border: 1px solid #f00;
}

.pop-up .instructions, .detailform .instructions {
  color: #f00;
}